import React from 'react'
import styles from './index.module.scss'
import cn from 'classnames'
import {FACEBOOK_URL, INSTAGRAM_URL, LINKEDIN_URL} from "../constants";

export const linkList = [
  // {
  //   title: 'For Members',
  //   links: [
  //     { title: 'Download App', url: '/download' },
  //     { title: 'Support', url: '/support' },
  //     { title: 'Member FAQs', url: '/member-faq' },
  //   ]
  // },
  // {
  //   title: 'For Businesses',
  //   links: [
  //     { title: 'How it works', url: '/how-it-works' },
  //     { title: 'Pricing', url: '/pricing' },
  //     { title: 'Business Sign Up', url: '/business-sign-up' },
  //     { title: 'Business Support', url: '/business-support' },
  //     { title: 'Business FAQs', url: '/business-faq' },
  //   ]
  // },
  {
    title: 'Company',
    links: [
      { title: 'About us', url: '/about' },
      { title: 'Reviews', url: '/customer-reviews' },
      // { title: 'Pricing', url: '/pricing' },
      // { title: 'Careers', url: '/careers' },
      // { title: 'Blog', url: '/blog' },
      { title: 'Terms & Conditions', url: '/terms' },
      { title: 'Privacy Policy', url: '/app-privacy' },
    ]
  },
]

const Links = ({ allTextPage, allBlogs }) => {

  return (
    <div className={cn('container', styles.link_container)}>

      <div className={styles.logo}>
        <a href="#" className="yesloyal_logo_svg">
          <img src="/logo.png" alt="yesloyal" height='52'/>
        </a>
      </div>

      {linkList.map(item => {
        return (
          <div key={item.title} className={styles.mobile_hide}>
            <span className={styles.link_type}>
              {item.title}
            </span>

            <ul className={styles.link_list_wrapper}>
              {item.links.map(link => {
                return (
                  <li key={link.title}><a href={link.url}>{link.title}</a></li>
                )
              })}
            </ul>
          </div>
        )
      })}

      <div className={styles.mobile_hide}>
        <span className={styles.link_type}>
          Our software solutions
        </span>

        <ul className={styles.link_list_wrapper}>
          <li>
            <a href='/instantreviews'>Instant Reviews</a>
          </li>
          <li>
            <a href="/retention">Loyalty & Retention</a>
          </li>
          <li>
            <a href="/referrals">Refer-a-friend</a>
          </li>
          {/*<li>*/}
          {/*  <a href="/pricing">Pricing</a>*/}
          {/*</li>*/}
        </ul>
      </div>

      <div className={styles.mobile_hide}>
        <span className={styles.link_type}>
          LEARN MORE
        </span>

        <ul className={styles.link_list_wrapper}>
          <li>
            <a href='/blog'>Blog</a>
          </li>

          {
            allTextPage?.map(({title, slug, showOnFooter}, i) => {
              return showOnFooter && (
                <li key={i}>
                  <a href={`/${slug}` || '#'}>{title?.length > 25 ? title.slice(0, 25) + '...' : title}</a>
                </li>
              )
            })
          }

          {
            allBlogs?.map(({title, slug, showOnFooter}, i) => {
              return showOnFooter && (
                <li key={i}>
                  <a href={`/${slug}` || '#'}>{title?.length > 25 ? title.slice(0, 25) + '...' : title}</a>
                </li>
              )
            })
          }

          {/*<li>*/}
          {/*  <a href="tel:0280918090">Call us: (02) 8091 8090</a>*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*  <a href= "mailto:info@yesloyal.com">Email: info@yesloyal.com</a>*/}
          {/*</li>*/}
        </ul>
      </div>

      <div className="banner_social_button_footer">
        <a className="footer_link-social" href={INSTAGRAM_URL}>
          <img src="/inst.svg" alt=""/>
        </a>
        {/*<a className="footer_link-social" href="#">*/}
        {/*  <img src="/twitter.svg" alt=""/>*/}
        {/*</a>*/}
        <a className="footer_link-social" href={LINKEDIN_URL}>
          <img src="/linkedin.svg" alt=""/>
        </a>
        <a className="footer_link-social" href={FACEBOOK_URL}>
          <img src="/facebook.svg" alt=""/>
        </a>
        <a className='footer_link-social' onClick={() => setOpen(false)} href='mailto:info@yesloyal.com' >
          <img src="/mail_icon.svg" alt="Mail"/>
        </a>
      </div>

    </div>
  )
}

export default Links
