import React from 'react'
import MobileNavbar from '../MobileNavbar/MobileNavbar'
import Link from 'next/link'

const Navbar = ({ allTextPage, allBlogs }) => {
  return (
    <>
      <MobileNavbar pages={allTextPage} allBlogs={allBlogs} />

      <header className="header">
        <a href="/">
          <img src="/logo.png" alt="yesloyal" height='52'/>
        </a>
        {
          allTextPage?.map(({title, slug, showOnHeader}, i) => {
            return showOnHeader && (
              <Link href={`/${slug}` || '#'} key={i}>
                <a className="header__link">{title?.length > 25 ? title.slice(0, 25) + '...' : title}</a>
              </Link>
            )
          })
        }
        {
          allBlogs?.map(({title, slug, showOnHeader}, i) => {
            return showOnHeader && (
              <Link href={`/${slug}` || '#'} key={i}>
                <a className="header__link">{title?.length > 25 ? title.slice(0, 25) + '...' : title}</a>
              </Link>
            )
          })
        }
        {/*<Link href='/download'>*/}
        {/*  <a className="download_btn">Download the app</a>*/}
        {/*</Link>*/}
      </header>
    </>
  )
};

export default Navbar;
