import styles from './Footer.module.scss'
import {FACEBOOK_URL, INSTAGRAM_URL, LINKEDIN_URL} from '../constants'
import Link from 'next/link'
import Links from "../Links";
import React from "react";

const Footer = ({ allTextPage, allBlogs }) => {

  return (
    <>
      <Links allTextPage={allTextPage} allBlogs={allBlogs} />
      {/*<footer className="footer">*/}
      {/*  <a href="#" className="yesloyal_logo_svg">*/}
      {/*    <img src="/logo.png" alt="yesloyal" height='52'/>*/}
      {/*  </a>*/}
      {/*  {*/}
      {/*    allTextPage?.map(({title, slug, showOnFooter}, i) => {*/}
      {/*      return showOnFooter && (*/}
      {/*        <Link href={`/${slug}` || '#'} key={i}>*/}
      {/*          <a className="footer_link">{title?.length > 25 ? title.slice(0, 25) + '...' : title}</a>*/}
      {/*        </Link>*/}
      {/*      )*/}
      {/*    })*/}
      {/*  }*/}

      {/*  <div className={styles.footer_links}>*/}
      {/*    <a className={styles.footer_links_item} href={INSTAGRAM_URL}>*/}
      {/*      <img src="/inst.svg" alt=""/>*/}
      {/*    </a>*/}
      {/*    /!*<a className={styles.footer_links_item} href="#">*!/*/}
      {/*    /!*  <img src="/twitter.svg" alt=""/>*!/*/}
      {/*    /!*</a>*!/*/}
      {/*    <a className={styles.footer_links_item} href={LINKEDIN_URL}>*/}
      {/*      <img src="/linkedin.svg" alt=""/>*/}
      {/*    </a>*/}
      {/*    <a className={styles.footer_links_item} href={FACEBOOK_URL}>*/}
      {/*      <img src="/facebook.svg" alt=""/>*/}
      {/*    </a>*/}
      {/*  </div>*/}
      {/*</footer>*/}
    </>
  )
};

export default Footer;
