import React, { useState } from 'react'
import { Squash as Hamburger } from 'hamburger-react'
import styles from './MobileNavbar.module.scss'
import cn from 'classnames';
import Link from 'next/link';
import {FACEBOOK_URL, INSTAGRAM_URL, LINKEDIN_URL} from '../constants'
import { linkList } from './../Links'

const MobileNavbar = ({pages, allTextPage}) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <nav id="nav" className={cn(styles.container, {
      [styles.opened]: isOpen,
    } )} role="navigation">
      <div className={styles.toggler}>
        <a href="/" className="header__menu_toggle__logo">
          <img src="/logo.png" className="yesloyal_logo_svg" alt="" height='52' />
        </a>
        <Hamburger toggled={isOpen} toggle={setOpen} />
      </div>
      <div>
        {
          isOpen &&
          <ul className={styles.list}>
            {
              pages.map(({title, slug, showOnHeader}, i) => {
                return showOnHeader && (
                  <li>
                    <Link href={`/${slug}` || '#'}>
                      <a className={styles.item} onClick={() => setOpen(false)}>{title.length > 25 ? title.slice(0, 25) + '...' : title}</a>
                    </Link>
                  </li>
                )
              })
            }
            <li className={styles.title}>Company</li>
            {
              linkList.map((item) => {
                return item.links.map((link) => {
                  return (
                    <li>
                      <a className={styles.item} href={link.url}>{link.title}</a>
                    </li>
                  )
                })
              })
            }

            <li className={styles.title}>Our software solutions</li>

            <li>
              <a className={styles.item} href='/instantreviews' >Instant Reviews</a>
            </li>
            <li>
              <a className={styles.item} href="/retention" >Loyalty & Retention</a>
            </li>
            <li>
              <a className={styles.item} href="/referrals" >Refer-a-friend</a>
            </li>
            {/*<li>*/}
            {/*  <a className={styles.item} href="/pricing" >Pricing</a>*/}
            {/*</li>*/}

            <li className={styles.title}>LEARN MORE</li>

            <li>
              <a className={styles.item} href='/blog'>Blog</a>
            </li>

            {
              allTextPage?.map(({title, slug, showOnFooter}, i) => {
                return showOnFooter && (
                  <li key={i}>
                    <a className={styles.item} href={`/${slug}` || '#'}>
                      {title?.length > 25 ? title.slice(0, 25) + '...' : title}
                    </a>
                  </li>
                )
              })
            }

            {/*<li>*/}
            {/*  <a className={styles.item} href= "mailto:info@yesloyal.com">Email: info@yesloyal.com</a>*/}
            {/*</li>*/}

            <li className={styles.item}>
              <a className={styles.item__social} onClick={() => setOpen(false)} href={INSTAGRAM_URL}>
                <img src="/inst.svg" alt="Instagram"/>
              </a>
              <a className={styles.item__social} onClick={() => setOpen(false)} href={LINKEDIN_URL}>
                <img src="/linkedin.svg" alt="Linkedin"/>
              </a>
              <a className={styles.item__social} onClick={() => setOpen(false)} href={FACEBOOK_URL}>
                <img src="/facebook.svg" alt="Facebook"/>
              </a>
              <a className={styles.item__social} onClick={() => setOpen(false)} href='mailto:info@yesloyal.com' >
                <img src="/mail_icon.svg" alt="Mail"/>
              </a>
            </li>

          </ul>
        }
      </div>

    </nav>
  )
};

export default MobileNavbar
